<template>
  <!-- sidebar -->
  <div class="
      sidenavbar
      bg-indigo-50
      z-50
      sidebar
      text-gray-700
      w-64
      space-y-6
      py-7
      px-2
      absolute
      inset-y-0
      left-0
      transform
      -translate-x-full
      md:relative md:translate-x-0
      transition
      duration-200
      ease-in-out
    ">
    <XIcon @click="toggleMobileMenu" class="mobileCloseIcon hidden h-6 w-6 ml-auto mr-0" />

    <a href="#" class="flex items-center space-x-2 px-4">
      <img src="../assets/ido-logo.png" class="w-15" alt="" />
    </a>

    <nav>
      <router-link :to="{ name: 'ManageBanner' }" class="
          block
          py-2.5
          px-4
          rounded
          transition
          duration-200
          hover:bg-blue-700 hover:text-white
        " active-class="bg-blue-700 text-white">
        Manage Banner
      </router-link>

      <router-link :to="{ name: 'subpage-banner' }" class="
          block
          py-2.5
          px-4
          rounded
          transition
          duration-200
          hover:bg-blue-700 hover:text-white
        " active-class="bg-blue-700 text-white">
        Change Subpage Banner
      </router-link>

      <router-link :to="{ name: 'ManageHomepageService' }" class="
          block
          py-2.5
          px-4
          my-2
          rounded
          transition
          duration-200
          hover:bg-blue-700 hover:text-white
        " active-class="bg-blue-700 text-white">
        Manage Homepage Service
      </router-link>

      <router-link :to="{ name: 'ManageGallery' }" class="
          block
          py-2.5
          px-4
          my-2
          rounded
          transition
          duration-200
          hover:bg-blue-700 hover:text-white
        " active-class="bg-blue-700 text-white">
        Manage Gallery
      </router-link>

      <button @click="showServiceDropDown = !showServiceDropDown" class="
          flex
          justify-between
          btn-block
          py-2.5
          px-4
          rounded
          transition
          duration-200
        ">
        Service
        <ChevronDownIcon class="
            inline
            w-4
            h-4
            mt-1
            pt-1
            ml-1
            transition-transform
            duration-200
            transform
          " />
      </button>
      <div v-if="showServiceDropDown" class="
          px-2
          py-2
          mb-1
          bg-indigo-400
          rounded-md
          shadow
          dark-mode:bg-gray-800
        ">
        <router-link :to="{ name: 'ManageServiceCategory' }" active-class="bg-gray-200 text-gray-900" class="
            block
            px-4
            py-2
            mt-2
            my-2
            text-sm
            font-semibold
            bg-transparent
            rounded-lg
            md:mt-0
            hover:text-gray-900
            focus:text-gray-900
            hover:bg-gray-200
            focus:bg-gray-200 focus:outline-none focus:shadow-outline
          " href="#">
          Manage Service Category
        </router-link>
        <router-link :to="{ name: 'ManageService' }" active-class="bg-gray-200 text-gray-900" class="
            block
            px-4
            py-2
            mt-2
            text-sm
            font-semibold
            bg-transparent
            rounded-lg
            md:mt-0
            hover:text-gray-900
            focus:text-gray-900
            hover:bg-gray-200
            focus:bg-gray-200 focus:outline-none focus:shadow-outline
          " href="#">
          Manage Service
        </router-link>
      </div>

      <button @click="showProductDropDown = !showProductDropDown" class="
          flex
          justify-between
          btn-block
          py-2.5
          px-4
          rounded
          transition
          duration-200
        ">
        Product
        <ChevronDownIcon class="
            inline
            w-4
            h-4
            mt-1
            pt-1
            ml-1
            transition-transform
            duration-200
            transform
          " />
      </button>
      <div v-if="showProductDropDown" class="
          px-2
          py-2
          mb-1
          bg-indigo-400
          rounded-md
          shadow
          dark-mode:bg-gray-800
        ">
        <router-link :to="{ name: 'ManageProductCategory' }" active-class="bg-gray-200 text-gray-900" class="
            block
            px-4
            py-2
            mt-2
            my-2
            text-sm
            font-semibold
            bg-transparent
            rounded-lg
            md:mt-0
            hover:text-gray-900
            focus:text-gray-900
            hover:bg-gray-200
            focus:bg-gray-200 focus:outline-none focus:shadow-outline
          " href="#">
          Manage Product Category
        </router-link>
        <router-link :to="{ name: 'ManageProduct' }" active-class="bg-gray-200 text-gray-900" class="
            block
            px-4
            py-2
            mt-2
            text-sm
            font-semibold
            bg-transparent
            rounded-lg
            md:mt-0
            hover:text-gray-900
            focus:text-gray-900
            hover:bg-gray-200
            focus:bg-gray-200 focus:outline-none focus:shadow-outline
          " href="#">
          Manage Product
        </router-link>
      </div>

      <router-link :to="{ name: 'ManageDoctor' }" active-class="bg-blue-700 text-white" class="
          block
          py-2.5
          px-4
          my-2
          rounded
          transition
          duration-200
          hover:bg-blue-700 hover:text-white
        ">
        Manage Doctor List
      </router-link>
      <router-link :to="{ name: 'ManageUserAccount' }" active-class="bg-blue-700 text-white" class="
          block
          py-2.5
          px-4
          my-2
          rounded
          transition
          duration-200
          hover:bg-blue-700 hover:text-white
        ">
        Manage User Account
      </router-link>
      <router-link :to="{ name: 'ManageCompanyInfo' }" active-class="bg-blue-700 text-white" class="
          block
          py-2.5
          px-4
          my-2
          rounded
          transition
          duration-200
          hover:bg-blue-700 hover:text-white
        ">
        Manage Company Info
      </router-link>
      <router-link :to="{ name: 'ManageBranch' }" active-class="bg-blue-700 text-white" class="
          block
          py-2.5
          px-4
          my-2
          rounded
          transition
          duration-200
          hover:bg-blue-700 hover:text-white
        ">
        Manage Branch Info
      </router-link>
      <router-link :to="{ name: 'UpdatePassword' }" active-class="bg-blue-700 text-white" class="
          block
          py-2.5
          px-4
          my-2
          rounded
          transition
          duration-200
          hover:bg-blue-700 hover:text-white
        ">
        Change My Password
      </router-link>
      <router-link :to="{ name: 'SEOManagement' }" active-class="bg-blue-700 text-white" class="
          block
          py-2.5
          px-4
          my-2
          rounded
          transition
          duration-200
          hover:bg-blue-700 hover:text-white
        ">
        SEO Management
      </router-link>
      <button @click="logout()" class="
          btn-block
          py-2.5
          px-4
          my-2
          rounded
          transition
          duration-200
          hover:bg-blue-700 hover:text-white
        ">
        <span class="flex">
          Logout
          <LogoutIcon class="h-5 w-5 ml-2 pt-1 cursor-pointer" />
        </span>
      </button>
    </nav>
  </div>

  <!-- mobile menu bar -->
  <div class="bg-gray-800 text-gray-100 flex justify-between md:hidden">
    <a href="#" class="block p-4 text-white font-bold">IDO's Clinic</a>

    <!-- mobile menu button -->
    <button @click="toggleMobileMenu" class="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700">
      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
      </svg>
    </button>
  </div>
</template>

<script>
import { LogoutIcon, ChevronDownIcon, XIcon } from "@heroicons/vue/outline";

export default {
  components: {
    LogoutIcon,
    ChevronDownIcon,
    XIcon,
  },
  data() {
    return {
      showServiceDropDown: false,
      showProductDropDown: false,
    };
  },
  mounted() {
    if (
      this.$route.name == "ManageServiceCategory" ||
      this.$route.name == "ManageService"
    ) {
      this.showServiceDropDown = true;
    }

    if (
      this.$route.name == "ManageProductCategory" ||
      this.$route.name == "ManageProduct"
    ) {
      this.showProductDropDown = true;
    }
  },
  methods: {
    async logout() {
      this.$store.dispatch("logout", this);
    },
    toggleMobileMenu() {
      document.querySelector(".sidebar").classList.toggle("-translate-x-full");
    },
  },
};
</script>

<style>
@media (max-width: 767px) {
  .sidebar {
    width: 100%;
  }

  .mobileCloseIcon {
    display: block;
  }
}
</style>